<template>
	<w-layout>
		<w-flex>
			<w-text-input
				v-model="application.marketing_url"
				auto-focus
				color="primary"
				:counter="rules.marketingUrlLength"
				:label="marketingUrlFieldName"
				placeholder="ex: https://www.welyb.fr"
				type="url"
				:rules="[
					value => {
						let result = true
						if (!value || value.trim().length <= 0) {
							result = $t('mobileapp.rules.field.required')
						}
						return result
					},
					value => {
						let result = true
						if (!value.match(/^http/i)) {
							result = $t('mobileapp.rules.field.url')
						}
						return result
					},
					value => {
						let result = true
						if (!value || value.trim().length > rules.marketingUrlLength) {
							result = $tc('mobileapp.rules.field.maxlen', 3, { fieldname: marketingUrlFieldName.toLowerCase(), max: rules.marketingUrlLength })
						}
						return result
					},
					value => {
						let result = true
						if (!value || !validateUrl(value)) {
							result = $t('mobileapp.rules.field.valid_url')
						}
						return result
					}
				]"
			></w-text-input>
		</w-flex>
	</w-layout>
</template>

<script>
export default {
	name: 'MobileAppMarketingURL',
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			rules: {
				marketingUrlLength: 2048
			}
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		marketingUrlFieldName: function () {
			return this.$t('mobileapp.fields.marketing_url')
		}
	},
	methods : {
		validateUrl: function (url) {
			const pattern = /^http[s]?:\/\/([a-zA-Z0-9-_~?&#:]+\.(?=[a-zA-Z0-9-_~?&#:])?)+[a-zA-Z0-9-_~?&#:]+$/
			return pattern.test(url)
		}
	}
}
</script>
